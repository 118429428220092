@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@200&display=swap);
@import url(https://fonts.cdnfonts.com/css/made-tommy-outline);
@-webkit-keyframes scaleUpDown {
  0%,
  100% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
  }
  75% {
    -webkit-transform: scaleY(0.95);
            transform: scaleY(0.95);
  }
  80% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
  }
}

@keyframes scaleUpDown {
  0%,
  100% {
    -webkit-transform: scaleY(1) scaleX(1);
            transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    -webkit-transform: scaleY(1.1);
            transform: scaleY(1.1);
  }
  75% {
    -webkit-transform: scaleY(0.95);
            transform: scaleY(0.95);
  }
  80% {
    -webkit-transform: scaleX(0.95);
            transform: scaleX(0.95);
  }
}

@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: skewX(0) scale(1);
            transform: skewX(0) scale(1);
  }
  50% {
    -webkit-transform: skewX(5deg) scale(0.9);
            transform: skewX(5deg) scale(0.9);
  }
}

@keyframes shake {
  0%,
  100% {
    -webkit-transform: skewX(0) scale(1);
            transform: skewX(0) scale(1);
  }
  50% {
    -webkit-transform: skewX(5deg) scale(0.9);
            transform: skewX(5deg) scale(0.9);
  }
}

@-webkit-keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@-webkit-keyframes glow {
  0%,
  100% {
    background-color: #ef5a00;
  }
  50% {
    background-color: #ff7800;
  }
}

@keyframes glow {
  0%,
  100% {
    background-color: #ef5a00;
  }
  50% {
    background-color: #ff7800;
  }
}
.fire {
  position: relative;
  /* margin-top: 40vh; */
  width: 40px;
  height: 40px;
  background-color: transparent;
}

.fire-main {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-animation: scaleUpDown 3s ease-out;
          animation: scaleUpDown 3s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-main .main-fire {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    farthest-corner at 10px 0,
    #d43300 0%,
    #ef5a00 95%
  );
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
}

.fire-main .particle-fire {
  position: absolute;
  top: 60%;
  left: 45%;
  width: 10px;
  height: 10px;
  background-color: #ef5a00;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
  -webkit-animation: particleUp 2s ease-out 0;
          animation: particleUp 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-right {
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-animation: shake 2s ease-out 0;
          animation: shake 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-right .main-fire {
  position: absolute;
  top: 15%;
  right: -25%;
  width: 80%;
  height: 80%;
  background-color: #ef5a00;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
}

.fire-right .particle-fire {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: #ef5a00;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
  -webkit-animation: particleUp 2s ease-out 0;
          animation: particleUp 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-left {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-animation: shake 3s ease-out 0;
          animation: shake 3s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-left .main-fire {
  position: absolute;
  top: 15%;
  left: -20%;
  width: 80%;
  height: 80%;
  background-color: #ef5a00;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 60% 40%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
}

.fire-left .particle-fire {
  position: absolute;
  top: 10%;
  left: 20%;
  width: 10%;
  height: 10%;
  background-color: #ef5a00;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px #d43322);
          filter: drop-shadow(0 0 10px #d43322);
  -webkit-animation: particleUp 3s infinite ease-out 0;
          animation: particleUp 3s infinite ease-out 0;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fire-bottom .main-fire {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 75%;
  height: 75%;
  background-color: #ff7800;
  -webkit-transform: scaleX(0.8) rotate(45deg);
          transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 100% 40%;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  -webkit-animation: glow 2s ease-out 0;
          animation: glow 2s ease-out 0;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}





